import React, { useCallback, useMemo, useState } from 'react';

import T from 'ecto-common/lib/lang/Language';
import { KeyValueSingleOption } from 'ecto-common/lib/KeyValueInput/KeyValueSingleOption';

import { AggregationType, SamplingInterval } from 'ecto-common/lib/API/APIGen';
import { SamplingIntervalText } from 'ecto-common/lib/types/SamplingInterval';
import { AggregationText } from 'ecto-common/lib/types/Aggregation';

import { FileFormatOptions } from 'js/components/ExportData/types';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';

export const OptionKey = Object.freeze({
  SAMPLING: 'sampling',
  FORMAT: 'format',
  AGGREGATION: 'aggregation'
});

export const ValidDataExportFileFormatOptions = [
  FileFormatOptions.CSV,
  FileFormatOptions.Excel
] as const;

export type OptionType = {
  sampling: SamplingInterval;
  format: (typeof ValidDataExportFileFormatOptions)[number];
  aggregation: AggregationType;
};

export const DEFAULT_DATA_OPTIONS: OptionType = {
  [OptionKey.SAMPLING]: SamplingInterval.Raw,
  [OptionKey.FORMAT]: FileFormatOptions.CSV,
  [OptionKey.AGGREGATION]: AggregationType.None
};

export const ControlledDataOptions = ({
  onOptionsChanged,
  formatOptions,
  options
}: {
  onOptionsChanged(options: OptionType): void;
  formatOptions?: FileFormatOptions[];
  options: OptionType;
}) => {
  const additionalOptions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      // Special case for sampling, if it changes then adjust aggregation options
      if (key === OptionKey.SAMPLING && value === SamplingInterval.Raw) {
        return { aggregation: AggregationType.None };
      } else if (
        key === OptionKey.SAMPLING &&
        options.aggregation === AggregationType.None
      ) {
        return { aggregation: AggregationType.Mean };
      }

      return null;
    },
    [options.aggregation]
  );

  const _onOptionsChanged = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      const newState = {
        ...options,
        [key]: value,
        ...additionalOptions(key, value)
      };
      onOptionsChanged(newState);
    },
    [additionalOptions, options, onOptionsChanged]
  );

  const _formatOptions = useMemo(
    () => formatOptions ?? [...ValidDataExportFileFormatOptions],
    [formatOptions]
  );

  const singleOptions = useMemo(() => {
    return [
      {
        dataKey: OptionKey.FORMAT,
        label: T.graphs.exportdialog.exportformat,
        value: (option: FileFormatOptions) => FileFormatOptions[option],
        options: _formatOptions,
        placeholder: FileFormatOptions.CSV,
        data: options,
        disabled: _formatOptions.length === 1
      },
      {
        dataKey: OptionKey.SAMPLING,
        label: T.graphs.exportdialog.samplinginterval,
        value: (option: SamplingInterval) => SamplingIntervalText[option],
        options: Object.keys(SamplingIntervalText),
        placeholder: SamplingInterval.Minute,
        data: options
      },
      {
        dataKey: OptionKey.AGGREGATION,
        label: T.graphs.exportdialog.aggregationtitle,
        value: (option: AggregationType) => AggregationText[option],
        options: enumValues(AggregationType).filter(
          (option) =>
            !(
              options.sampling !== SamplingInterval.Raw &&
              option === AggregationType.None
            ) &&
            option !== AggregationType.NoneWithTags &&
            option !== AggregationType.Unknown
        ),
        placeholder: AggregationType.Mean,
        disabled: options.sampling === SamplingInterval.Raw,
        data: options
      }
    ];
  }, [_formatOptions, options]);

  return (
    <>
      {singleOptions.map((option) => (
        <KeyValueSingleOption
          key={option.dataKey}
          {...option}
          onOptionsChanged={_onOptionsChanged}
        />
      ))}
    </>
  );
};

const DataOptions = ({
  onOptionsChanged,
  formatOptions,
  defaultDataOptions = DEFAULT_DATA_OPTIONS
}: {
  onOptionsChanged(options: OptionType): void;
  formatOptions?: FileFormatOptions[];
  defaultDataOptions?: OptionType;
}) => {
  const [state, setState] = useState<OptionType>({
    ...DEFAULT_DATA_OPTIONS,
    ...defaultDataOptions
  });

  const _onOptionsChanged = useCallback(
    (options: OptionType) => {
      setState(options);
      onOptionsChanged(options);
    },
    [onOptionsChanged]
  );

  return (
    <ControlledDataOptions
      formatOptions={formatOptions}
      onOptionsChanged={_onOptionsChanged}
      options={state}
    />
  );
};

export default DataOptions;
